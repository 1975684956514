import React from 'react';
import Button from '@components/Button';
import Container from '@components/Container';
import Typography from '@components/Typography';
import links from '@constants/links';
import './RequestDemo.scss';

const RequestDemo = () => {
  return (
    <Container>
      <div className="page--contact-sales__request-demo">
        <Typography
          className="page--contact-sales__request-demo__Title"
          fontSize="subheading-1"
          fontWeight="semi-bold"
          color="neutral-main"
        >
          Want to see TileDB in action?
        </Typography>
        <div className="page--contact-sales__request-demo__buttons">
          <Button primary to={links.requestDemo}>
            Book a discovery call
          </Button>
          <Button outlined href={links.console.signup}>
            Sign up
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default RequestDemo;
