import React from 'react';
import './Intro.scss';
import Typography from '@components/Typography';
import Container from '@components/Container';

const Intro: React.FC = () => {
  return (
    <div className="page--contact-sales__Intro-wrapper">
      <Container>
        <div className="page--contact-sales__Intro">
          <div className="page--contact-sales__Heading">
            <Typography as="h2" fontSize="heading-4" fontWeight="bold" color="brand-800">
              Contact sales
            </Typography>
            <Typography as="p" fontSize="heading-1" color="neutral-900" fontWeight="bold">
              Our experts are here to answer your questions
            </Typography>
          </div>
          <div className="page--contact-sales__Illustration" />
        </div>
      </Container>
    </div>
  );
};

export default Intro;
