import React from 'react';
import Typography from '@components/Typography';
import './FormSection.scss';
import HubspotForm from 'react-hubspot-form';
import links from '@constants/links';
import Container from '@components/Container';
import Arrow from './Arrow.svg';
import Check from './Check.svg';

const FormSection = () => {
  return (
    <Container>
      <div className="page--contact-sales__FormSection">
        <div className="page--contact-sales__FormSection__Text">
          <div className="page--contact-sales__FormSection__Text__First">
            <Typography as="h4" fontWeight="bold" fontSize="heading-4" color="neutral-900">
              Let us help
            </Typography>
            <ListItem text="Find the right solution for your data" />
            <ListItem text="Understand pricing & deployment" />
            <ListItem text="Get a product tour" />
            <ListItem text="Ask about integrations" />
          </div>

          <div className="page--contact-sales__FormSection__Text__Second">
            <Typography as="h4" fontWeight="bold" fontSize="heading-4" color="neutral-900">
              Already a customer?
            </Typography>
            <Typography as="p">If you are encountering a technical issue, our customer support team will be happy to assist.</Typography>
            <LinkItem to={links.support} text="Get in touch with help and support" />
            <LinkItem to={links.forum} text="Ask the TileDB community" />
          </div>
        </div>

        <div className="page--contact-sales__FormSection__Form">
          <HubspotForm portalId="7741339" formId="482afd42-aa76-40f0-b7f7-8c5143d2cc17" loading={<div>Loading...</div>} />
        </div>

        <div className="page--contact-sales__FormSection__Text__Second--mobile">
          <Typography as="h4" fontWeight="bold" fontSize="heading-4" color="neutral-900">
            Already a customer?
          </Typography>
          <Typography as="p">If you are encountering a technical issue, our customer support team will be happy to assist.</Typography>
          <LinkItem to={links.support} text="Get in touch with help and support" />
          <LinkItem to={links.forum} text="Ask the TileDB community" />
        </div>
      </div>
    </Container>
  );
};

interface ListItemProps {
  text: string;
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const { text } = props;
  return (
    <div className="page--contact-sales__FormSection__ListItem">
      <img src={Check} alt="check" />
      <Typography>{text}</Typography>
    </div>
  );
};

interface LinkItemProps {
  text: string;
  to: string;
}

const LinkItem: React.FC<LinkItemProps> = (props) => {
  const { text, to } = props;
  return (
    <a href={to} target="_blank" rel="noreferrer noopener" className="page--contact-sales__FormSection__LinkItem">
      <Typography fontWeight="semi-bold" color="brand-800">
        {text}
      </Typography>
      <img src={Arrow} alt="arrow" />
    </a>
  );
};

export default FormSection;
